import React, { Component } from "react";

class Introduction2 extends React.Component {
  render(){
    const styles = {
      container: {
        maxWidth: "1200px",
        margin: "auto",
        fontFamily: "Arial, sans-serif",
        color: "#fff",
        backgroundColor: "#1b1b2f",
      },
      hero: {
        backgroundImage: `url("https://via.placeholder.com/1500x600")`,
        height: "600px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        position: "relative",
        color: "white",
      },
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "20px",
        borderRadius: "10px",
      },
      heroButtons: {
        marginTop: "15px",
      },
      buttonPrimary: {
        margin: "5px",
        padding: "10px 20px",
        backgroundColor: "#ff6f61",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      },
      buttonSecondary: {
        margin: "5px",
        padding: "10px 20px",
        backgroundColor: "#1f78b4",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      },
      contentSection: {
        padding: "20px",
        margin: "20px 0",
        backgroundColor: "#202040",
        borderRadius: "10px",
      },
      darkSection: {
        backgroundColor: "#1f1f33",
      },
      cardContainer: {
        display: "flex",
        gap: "20px",
      },
      card: {
        backgroundColor: "#28284a",
        padding: "10px",
        borderRadius: "10px",
        flex: 1,
      },
      iconCards: {
        display: "flex",
        gap: "20px",
      },
      iconCard: {
        textAlign: "center",
        flex: 1,
      },
      iconCardImage: {
        width: "100px",
        height: "100px",
        marginBottom: "10px",
      },
      imageGallery: {
        display: "flex",
        gap: "20px",
        justifyContent: "center",
      },
      galleryImage: {
        width: "300px",
        borderRadius: "10px",
      },
    };

    return (
<div style={styles.container}>
    {/* Hero Section */}
    <section style={styles.hero}>
      <div style={styles.overlay}>
        <h1>ChatAssistant: Revolutionizing Government Communication</h1>
        <p>
          Empowering citizens, streamlining services, and building stronger
          communities through cutting-edge AI technology.
        </p>
        <div style={styles.heroButtons}>
          <button style={styles.buttonPrimary}>Learn More</button>
          <button style={styles.buttonSecondary}>Request Demo</button>
        </div>
      </div>
    </section>

    {/* Strengthening Community Ties */}
    <section style={styles.contentSection}>
      <h2>Strengthening Community Ties</h2>
      <div style={styles.cardContainer}>
        <div style={styles.card}>
          <h3>1. Targeted Assistance</h3>
          <p>
            ChatAssistant identifies and guides people in need to access
            government benefits efficiently.
          </p>
        </div>
        <div style={styles.card}>
          <h3>2. Personalized Support</h3>
          <p>
            AI-powered responses provide tailored information based on
            individual cases and needs.
          </p>
        </div>
        <div style={styles.card}>
          <h3>3. Civic Engagement</h3>
          <p>
            Users learn about relevant propositions and how to support
            initiatives that matter to them.
          </p>
        </div>
      </div>
    </section>

    {/* Building Enduring Networks */}
    <section style={{ ...styles.contentSection, ...styles.darkSection }}>
      <h2>Building Enduring Networks</h2>
      <div style={styles.cardContainer}>
        <div style={styles.card}>
          <h3>Common Interest</h3>
          <p>
            ChatAssistant connects individuals with shared concerns, fostering
            support networks across geographic boundaries.
          </p>
        </div>
        <div style={styles.card}>
          <h3>Nonprofit Partnerships</h3>
          <p>
            Users are directed to relevant organizations offering
            government-funded benefits and services.
          </p>
        </div>
        <div style={styles.card}>
          <h3>Sustained Engagement</h3>
          <p>
            Enduring connections encourage ongoing participation in community
            improvement efforts.
          </p>
        </div>
      </div>
    </section>

    {/* Inclusive Communication */}
    <section style={styles.contentSection}>
      <h2>Inclusive Communication</h2>
      <div style={styles.iconCards}>
        {["Multilingual", "Simplified", "Accessibility", "Efficiency"].map(
          (title, i) => (
            <div key={i} style={styles.iconCard}>
              <img
                src={`https://via.placeholder.com/100`}
                alt={title}
                style={styles.iconCardImage}
              />
              <h3>{title} Support</h3>
              <p>
                {title === "Efficiency"
                  ? "Quick, AI-driven responses save time for users with busy schedules."
                  : `${title} feature description.`}
              </p>
            </div>
          )
        )}
      </div>
    </section>

    {/* Meeting ADA Standards */}
    <section style={{ ...styles.contentSection, ...styles.darkSection }}>
      <h2>Meeting ADA Standards</h2>
      <p>Equivalent Experience | Time Efficiency | Personalized Interaction</p>
    </section>

    {/* Improving Government Efficiency */}
    <section style={styles.contentSection}>
      <h2>Improving Government Efficiency</h2>
      <div style={styles.cardContainer}>
        {["Reduced Workload", "24/7 Availability", "Data-Driven Insights"].map(
          (title, i) => (
            <div key={i} style={styles.card}>
              <h3>{title}</h3>
              <p>{`${title} description.`}</p>
            </div>
          )
        )}
      </div>
    </section>

    {/* Flexible Implementation */}
    <section style={styles.contentSection}>
      <h2>Flexible Implementation</h2>
      <div style={styles.imageGallery}>
        {["Mobile App", "Chat Bubble"].map((alt, i) => (
          <img
            key={i}
            src="https://via.placeholder.com/300x500"
            alt={alt}
            style={styles.galleryImage}
          />
        ))}
      </div>
    </section>

    {/* Our Core Values */}
    <section style={{ ...styles.contentSection, ...styles.darkSection }}>
      <h2>Our Core Values</h2>
      <div style={styles.cardContainer}>
        <div style={styles.card}>
          <h3>Social Responsibility</h3>
          <p>
            We commit to donating one-third of profits to charitable causes and
            social initiatives.
          </p>
        </div>
        <div style={styles.card}>
          <h3>Efficiency and Convenience</h3>
          <p>
            Our AI-powered solution streamlines government services, saving
            time and resources.
          </p>
        </div>
      </div>
    </section>
  </div>
    );
  }
}




class Introduction3 extends Component {
  render() {
    const styles = {
      container: {
        fontFamily: "Arial, sans-serif",
        backgroundColor: "#121212",
        color: "#fff",
        margin: 0,
        padding: 0,
        lineHeight: "1.6",
      },
      section: {
        padding: "50px 20px",
        textAlign: "center",
      },
      darkSection: {
        backgroundColor: "#1a1a1a",
      },
      hero: {
        position: "relative",
        backgroundImage:
          "url('https://via.placeholder.com/1920x500')", // Replace with actual image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      },
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        padding: "20px",
        borderRadius: "10px",
      },
      heroHeading: {
        fontSize: "2.5rem",
        margin: "10px 0",
      },
      heroText: {
        fontSize: "1.2rem",
        marginBottom: "20px",
      },
      buttons: {
        display: "flex",
        justifyContent: "center",
        gap: "10px",
      },
      buttonPrimary: {
        padding: "10px 20px",
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      },
      buttonSecondary: {
        padding: "10px 20px",
        backgroundColor: "#6c757d",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
      },
      heading: {
        fontSize: "2rem",
        marginBottom: "20px",
      },
      subText: {
        fontSize: "1rem",
        marginBottom: "40px",
      },
      cardContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
        justifyContent: "center",
      },
      card: {
        backgroundColor: "#2a2a2a",
        borderRadius: "10px",
        padding: "20px",
        width: "300px",
        textAlign: "left",
      },
      cardHeading: {
        fontSize: "1.5rem",
        marginBottom: "10px",
      },
      cardText: {
        fontSize: "1rem",
      },
      iconContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "20px",
      },
      iconCard: {
        backgroundColor: "#2a2a2a",
        borderRadius: "10px",
        padding: "20px",
        width: "200px",
        textAlign: "center",
      },
      iconImage: {
        width: "100px",
        height: "100px",
        marginBottom: "10px",
      },
      footerText: {
        fontSize: "0.9rem",
        color: "#aaa",
      },
    };

    return (
      <div style={styles.container}>
      {/* Hero Section */}
      <section style={styles.heroSection}>
        <h1 style={styles.heroTitle}>
          ChatAssistant: Revolutionizing Government Communication
        </h1>
        <p style={styles.heroSubtitle}>
          Empowering citizens, streamlining services, and building stronger
          communities through cutting-edge AI technology.
        </p>
        <div style={styles.heroButtons}>
          <button style={styles.primaryButton}>Learn More</button>
          <button style={styles.secondaryButton}>Request Demo</button>
        </div>
      </section>

      {/* Strengthening Community Ties Section */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Strengthening Community Ties</h2>
        <div style={styles.cardContainer}>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>1. Targeted Assistance</h3>
            <p style={styles.cardDescription}>
              ChatAssistant identifies and guides people in need to access
              government benefits efficiently.
            </p>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>2. Personalized Support</h3>
            <p style={styles.cardDescription}>
              AI-powered responses provide tailored information based on
              individual cases and needs.
            </p>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>3. Civic Engagement</h3>
            <p style={styles.cardDescription}>
              Users learn about relevant propositions and how to support
              initiatives that matter to them.
            </p>
          </div>
        </div>
      </section>

      {/* Building Enduring Networks */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Building Enduring Networks</h2>
        <div style={styles.cardContainer}>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Common Interest</h3>
            <p style={styles.cardDescription}>
              ChatAssistant connects individuals with shared concerns,
              fostering support networks across geographic boundaries.
            </p>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Nonprofit Partnerships</h3>
            <p style={styles.cardDescription}>
              Users are directed to relevant organizations offering
              government-funded benefits and services.
            </p>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Sustained Engagement</h3>
            <p style={styles.cardDescription}>
              Enduring connections encourage ongoing participation in
              community improvement efforts.
            </p>
          </div>
        </div>
      </section>

      {/* Inclusive Communication */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Inclusive Communication</h2>
        <div style={styles.iconCardContainer}>
          <div style={styles.iconCard}>
            <h3 style={styles.iconCardTitle}>Multilingual Support</h3>
            <p style={styles.iconCardDescription}>
              ChatAssistant breaks language barriers to connect diverse
              communities effectively.
            </p>
          </div>
          <div style={styles.iconCard}>
            <h3 style={styles.iconCardTitle}>Simplified Content</h3>
            <p style={styles.iconCardDescription}>
              Complex government information is organized into easy-to-
              understand formats.
            </p>
          </div>
          <div style={styles.iconCard}>
            <h3 style={styles.iconCardTitle}>Accessibility Features</h3>
            <p style={styles.iconCardDescription}>
              Inclusive input and output options cater to users with various
              disabilities.
            </p>
          </div>
          <div style={styles.iconCard}>
            <h3 style={styles.iconCardTitle}>Time-Efficient</h3>
            <p style={styles.iconCardDescription}>
              Quick, AI-driven responses save time for users with busy
              schedules.
            </p>
          </div>
        </div>
      </section>

      {/* Meeting ADA Standards */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Meeting ADA Standards</h2>
        <p style={styles.text}>
          Equivalent Experience | Time Efficiency | Personalized Interaction
        </p>
      </section>

      {/* Improving Government Efficiency */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Improving Government Efficiency</h2>
        <div style={styles.cardContainer}>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Reduced Workload</h3>
            <p style={styles.cardDescription}>
              ChatAssistant handles high volumes of queries, freeing up
              government employees for complex tasks.
            </p>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>24/7 Availability</h3>
            <p style={styles.cardDescription}>
              AI assistance is available round-the-clock, enhancing service
              delivery and citizen satisfaction.
            </p>
          </div>
          <div style={styles.card}>
            <h3 style={styles.cardTitle}>Data-Driven Insights</h3>
            <p style={styles.cardDescription}>
              User interactions provide valuable data for improving government
              services and policies.
            </p>
          </div>
        </div>
      </section>

      {/* Our Core Values */}
      <section style={styles.section}>
        <h2 style={styles.sectionTitle}>Our Core Values</h2>
        <div style={styles.coreValuesContainer}>
          <div style={styles.coreValue}>
            <h3 style={styles.coreValueTitle}>Social Responsibility</h3>
            <p style={styles.coreValueDescription}>
              We commit to donating one-third of profits to charitable causes
              and social initiatives.
            </p>
          </div>
          <div style={styles.coreValue}>
            <h3 style={styles.coreValueTitle}>Efficiency and Convenience</h3>
            <p style={styles.coreValueDescription}>
              A one-stop solution streamlining government services.
            </p>
          </div>
          <div style={styles.coreValue}>
            <h3 style={styles.coreValueTitle}>Continuous Improvement</h3>
            <p style={styles.coreValueDescription}>
              We remain humble and open to feedback.
            </p>
          </div>
          <div style={styles.coreValue}>
            <h3 style={styles.coreValueTitle}>
              Respect and Gratitude
            </h3>
            <p style={styles.coreValueDescription}>
              Valuing every user interaction.
            </p>
          </div>
        </div>
      </section>
    </div>
    );
  }
}


class Introduction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      htmlContent: ''
    };
  }

  componentDidMount() {
    fetch('/ChatAssistant Revolutionizing Government Communication.html')
      .then((response) => response.text())
      .then((htmlString) => {
        this.setState({ htmlContent: htmlString });
      });
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} />;
  }
}


export default Introduction;