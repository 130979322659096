import React from 'react';

const PrivacyPolicy = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      fontFamily: 'Arial, sans-serif',
      lineHeight: '1.6',
    },
    header: {
      textAlign: 'center',
      color: '#333',
    },
    section: {
      marginBottom: '20px',
    },
    sectionTitle: {
      color: '#2c3e50',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Privacy Policy for Ads_AI</h1>
      <p><strong>Effective Date: December 17, 2024</strong></p>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>1. Introduction</h2>
        <p>Welcome to Ads_AI. This Privacy Policy is designed to inform you about our data practices, even though we do not collect or process any personal information from our users.</p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>2. No Data Collection</h2>
        <p>Ads_AI does not collect, store, use, or share any personal information from its users. We do not have access to any user data, and we do not track or monitor user activity within the app.</p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>3. Third-Party Services</h2>
        <p>Ads_AI does not integrate with any third-party services that might collect user data.</p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>4. Changes to This Policy</h2>
        <p>We may update this Privacy Policy from time to time. We will notify users of any changes by updating the "Effective Date" at the top of this policy.</p>
      </div>

      <div style={styles.section}>
        <h2 style={styles.sectionTitle}>5. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us at [Your Contact Information].</p>
      </div>

      <p>By using Ads_AI, you acknowledge that you have read and understood this Privacy Policy.</p>
    </div>
  );
};

export default PrivacyPolicy;
